import React, { useState } from 'react';
import { FlightTabs, FlightTabPanel, FlightButton } from '@flybits/design-system';
import './Analytics.scss';
import AnalyticsDashboard from './AnalyticsDashboard/AnalyticsDashboard';
import ExperienceDashboard, { TAnalyticsRouteParams } from './Experience/ExperienceDashboard';
import { useHistory, useParams } from 'react-router-dom';

// CLASSES
const CLASS_MAIN = 'analytics-container';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  HEADER: `${CLASS_MAIN}__header`,
  CONTAINER: `${CLASS_MAIN}__container`,
  TABS: `${CLASS_MAIN}__tabs`,
  ADDBUTTON: `${CLASS_MAIN}__addbutton`,
};

function Analytics() {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const history = useHistory();
  const { pid } = useParams<TAnalyticsRouteParams>();

  return (
    <main className={CLASSES.BODY}>
      <div className={CLASSES.HEADER}>
        <div className={CLASSES.TABS}>
          <FlightTabs
            labels={[
              { key: 1, name: 'Analytics dashboard' },
              { key: 2, name: 'Experience dashboard' },
            ]}
            selectedTab={selectedTab}
            onSelect={(e: { key: number }) => {
              setSelectedTab(e.key);
            }}
          />
        </div>

        {selectedTab === 2 && ( // Only show if it is experience dashboard
          <div className={CLASSES.ADDBUTTON}>
            <FlightButton
              type="submit"
              iconLeft="add"
              label="Add chart"
              onClick={() => history.push(`/project/${pid}/analytics/v2/experiences/create`)}
            ></FlightButton>
          </div>
        )}
      </div>

      <div className={CLASSES.CONTAINER}>
        {selectedTab === 1 && (
          <FlightTabPanel>
            <AnalyticsDashboard />
          </FlightTabPanel>
        )}

        {selectedTab === 2 && (
          <FlightTabPanel>
            <ExperienceDashboard />
          </FlightTabPanel>
        )}
      </div>
    </main>
  );
}

export default Analytics;
