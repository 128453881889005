import localforage from 'localforage';

/**
 * Offline Browser storage utility using localforage.
 * https://github.com/localForage/localForage
 *
 * Localforage uses async storage. The call should be made using async/await or then/catch.
 */
class Storage {
  private store: LocalForage;
  constructor() {
    // Appears to solve a very strange bug with localforage: https://bugs.webkit.org/show_bug.cgi?id=226547 - https://flybits.atlassian.net/browse/PET-2534
    indexedDB.databases();
    localforage.config({
      driver: localforage.INDEXEDDB,
      name: 'experience-studio',
      version: 1.0,
      size: 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'templated_experience',
    });
    this.store = localforage;
  }

  clear(): Promise<void> {
    return this.store.clear();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem(key: string): Promise<any> {
    return this.store.getItem(key);
  }

  removeItem(key: string): Promise<void> {
    return this.store.removeItem(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: string, value: any): Promise<any> {
    return this.store.setItem(key, value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItems(objects: { [key: string]: any }): Promise<any[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<any>[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(objects).forEach((key: any) => {
      promises.push(this.setItem(key, objects[key]));
    });
    return Promise.all(promises);
  }
}

export default Storage;
