import React, { useState } from 'react';
import './RecommendationsSelector.scss';
import { getIcon } from '@flybits/design-system';
import { RECOMMENDED_METRICS } from '../constants';
const MAIN_CLASS = 'recommendation-selector';
const CLASSES = {
  INFO: `${MAIN_CLASS}__info`,
  LIST: `${MAIN_CLASS}__list`,
  ITEM: `${MAIN_CLASS}__item`,
};
interface RecommendationsSelectorProps {
  onChange: (s: {
    icon: string;
    metric: { key: string; name: string };
    time: { period: number; interval: string };
  }) => void;
}

const RecommendedItem: React.FC<{
  selected?: boolean;
  icon: string | JSX.Element;
  metric: { key: string; name: string };
  time: { period: number; interval: string };
  onClick: () => void;
}> = ({ selected, icon, metric, time, onClick }) => {
  return (
    <button onClick={onClick} className={`${CLASSES.ITEM} ${selected ? 'selected' : ''}`}>
      {typeof icon === 'string' ? getIcon(icon, {}) : icon} For the selected experience, show me{' '}
      <span>{`${metric.name}`}</span> in the past <span>{`${time.period} ${time.interval}`}</span>
    </button>
  );
};
export default function RecommendationsSelector({ onChange }: RecommendationsSelectorProps) {
  const [selected, setSelected] = useState(1);
  const handleClick = (selectedItem: number) => {
    onChange(RECOMMENDED_METRICS[selectedItem]);
    setSelected(selectedItem);
  };
  return (
    <section className={MAIN_CLASS}>
      <div className={CLASSES.INFO}>
        {getIcon('error', {})} In order to utilize recommendations make sure you have an experience selected
      </div>
      <ul className={CLASSES.LIST}>
        {RECOMMENDED_METRICS.map((metric, i) => (
          <RecommendedItem key={i} selected={i === selected} {...metric} onClick={() => handleClick(i)} />
        ))}
      </ul>
    </section>
  );
}
