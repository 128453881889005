import React from 'react';
import './ExperienceTile.scss';
import MenuIcon from 'assets/icons/menu-icon.svg';
import { FlightOverflowMenu } from '@flybits/design-system';
import { DropdownOptions } from 'interface/shared/dropdown.interface';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: string;
  description?: string;
  graph: any;
}

// CLASSES
const CLASS_MAIN = 'experience-tile';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  HEADER: `${CLASS_MAIN}__header`,
  GRAPH: `${CLASS_MAIN}__graph`,
};

const HEADER = {
  TOPBAR: `${CLASSES.HEADER}__topbar`,
  TITLE: `${CLASSES.HEADER}__title`,
  MENU: `${CLASSES.HEADER}__menu`,
  MENU_ICON: `${CLASSES.HEADER}__menu-icon`,
  DESCRIPTION: `${CLASSES.HEADER}__description`,
};

function ExperienceTile(props: Props) {
  const title = props?.title ?? '';
  const description = props?.description || '';
  const graphImg = props?.graph || '';

  const contextMenuOptions: Array<Array<DropdownOptions>> = [
    [
      {
        key: 1,
        name: 'Edit Graph',
        disabled: false,
        onClick: () => null,
      },
      {
        key: 2,
        name: 'Export CSV',
        disabled: false,
        onClick: () => null,
      },
      {
        key: 2,
        name: 'Remove',
        disabled: false,
        onClick: () => null,
      },
    ],
  ];

  return (
    <main className={CLASSES.BODY}>
      <div className={CLASSES.HEADER}>
        <div className={HEADER.TOPBAR}>
          <div className={HEADER.TITLE}>{title}</div>
          <div className={HEADER.MENU}>
            <img className={HEADER.MENU_ICON} alt="graph-menu" src={MenuIcon} />
            <FlightOverflowMenu
              direction={'bottom'}
              isOpenRight={false}
              disabled={false}
              optionGroups={contextMenuOptions}
            />
          </div>
        </div>
        <div className={HEADER.DESCRIPTION}>{description}</div>
      </div>
      <div className={CLASSES.GRAPH}>{graphImg}</div>
    </main>
  );
}

export default ExperienceTile;
