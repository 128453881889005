import './ContentNode.scss';

import { ContentStateItem } from 'store/content/content.type';
import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { useFlowBuilderContext, TContentSlideoutProps } from 'pages/FlowBuilder/context/flow-builder.context';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { mapStateItemToListItem } from 'helpers/content.helper';
import useSettings from 'hooks/useSetting';
import BaseNode, { BaseNodeProps, FlowNodeMenuItem } from 'components/FlowBuilder/FlowNodes/BaseNode/BaseNode';
import React, { useCallback, useMemo } from 'react';
import IconContent from 'pages/FlowBuilder/assets/icons/IconContent';
import { FlightTooltip } from '@flybits/design-system';
import { CONTENT_LABELS } from 'components/ExperienceCanvas/constants';
import { CONTENT_MANAGER_STEPS, TOUCHPOINT_STATUSES } from 'components/ExperienceCanvas/types';
import IconContentMultiPage from 'pages/FlowBuilder/assets/icons/IconContentMultiPage';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import OptionalNodeWrapper from '../OptionalNodeWrapper/OptionalNodeWrapper';
import { ActionTypes } from 'store/actionTypes';
import useJourneyStatus from 'hooks/useJourneyStatus';
import IconContentFlow from 'pages/FlowBuilder/assets/icons/IconContentFlow';
import { StepMetadata } from 'interface/experience/experience.interface';
import { isEmpty } from 'lodash';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { FlowNodeTypes } from '../node.types';
import { validateStepAction } from 'validators/ExperienceCanvas/journey.validator';
import { getTooltipIcon } from 'pages/FlowBuilder/helpers/flow-builder.helpers';

const MAIN_CLASS = 'content-node';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  LABEL_DISABLED: `${MAIN_CLASS}__label--disabled`,
  LABEL_TEXT: `${MAIN_CLASS}__label__text`,
  LABEL_NAME: `${MAIN_CLASS}__label__name`,
  NAME_TOOLTIP: `${MAIN_CLASS}__name-tooltip`,
};

type ContentNodeProps = {
  data: {
    content: ContentStateItem;
    stepIndex: number;
  };
};

function ContentNode(props: ContentNodeProps) {
  const {
    data: { content, stepIndex },
  } = props;

  const flowBuilderContext = useFlowBuilderContext();
  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const contentListItem = mapStateItemToListItem(content, defaultLang, 'primary');
  const dispatch = useDispatch();
  const { isJourneyLocked } = useJourneyStatus();
  const contentNodeName = useSelector((state) => {
    const stepsMetadata = JSON.parse(state.te.journey.metadata?.stepMetadata ?? '[]') as StepMetadata[];

    return stepsMetadata[stepIndex]?.contentNodes?.[content.refId]?.name || '';
  });
  const dependencyGraph = useSelector((state) => state.te.journey.dependencyGraph);

  const contentDisplayName = contentNodeName || content.localizations?.[defaultLang]?.name || 'Content';
  const contentNodeLabel = content.actionType ? CONTENT_LABELS[content.actionType] : '';
  const contentLabels = content.payload ? (`labels` in content.payload ? content.payload.labels : []) : [];
  const isMultiPageContent = contentLabels?.includes('multipage');
  const isOptional = content.isOptional;
  const isOptionalContentEnabled = !!content.enableOptionalNode;
  const contentIconUrl = content.iconUrl;

  const handleRenameContent = useCallback(() => {
    flowBuilderContext.modals.rename.setData({
      entity: content,
      entityType: JourneyComponentTypes.CONTENT,
      entityNodeName: contentDisplayName,
      stepIndex,
    });
  }, [content, flowBuilderContext.modals.rename, stepIndex, contentDisplayName]);

  const { errorStatus, errorMessage } = useSelector((state) => {
    const { errorStatus, errors } = validateStepAction(state, stepIndex, FlowNodeTypes.CONTENT, content.refId);
    // currently only revealing the first error on a node.
    const errorMessage = errors[0]?.err ?? '';

    if (errorStatus === '') {
      return { errorStatus: TOUCHPOINT_STATUSES.COMPLETE, errorMessage };
    } else if (errorStatus === ERROR_TYPES.INCOMPLETE) {
      return { errorStatus: TOUCHPOINT_STATUSES.INCOMPLETE, errorMessage };
    } else {
      return { errorStatus: TOUCHPOINT_STATUSES.ERROR, errorMessage };
    }
  });

  const handleEditContent = useCallback(() => {
    flowBuilderContext.sidePanels.content.setStepIndex(stepIndex);
    flowBuilderContext.sidePanels.content.setContentContext((prev) => {
      return {
        ...prev,
        headerInfo: {
          ...prev.headerInfo,
          goBackTitle: 'Content Editor',
          goBackSubTitle: 'Currently editing from Flow Builder',
        },
        isOpen: true,
        startingStep: CONTENT_MANAGER_STEPS.CONTENT_EDITOR,
        contentRefId: contentListItem?.refId,
      };
    });
  }, [contentListItem, flowBuilderContext.sidePanels.content, stepIndex]);

  const handleCreateAndSwapContent = useCallback(
    (contextOverrides?: Partial<TContentSlideoutProps>) => {
      flowBuilderContext.sidePanels.content.setStepIndex(stepIndex);
      flowBuilderContext.sidePanels.content.setContentContext((prev) => {
        return {
          ...prev,
          headerInfo: {
            ...prev.headerInfo,
            goBackTitle: 'Select/Create a content',
            goBackSubTitle: 'From Flow Builder',
          },
          isOpen: true,
          contentRefId: content.refId,
          ...contextOverrides,
        };
      });
    },
    [content.refId, flowBuilderContext.sidePanels.content, stepIndex],
  );

  const handleRemoveContent = useCallback(() => {
    dispatch({
      type: 'REMOVE_CONTENT',
      payload: { refIds: [content.refId], stepIdx: stepIndex ?? 0, required: [!content.isOptional] },
    });
  }, [dispatch, content, stepIndex]);

  const handleToggleOptionalContent = () => {
    dispatch({
      type: ActionTypes.SET_UI_NODE_ATTR,
      payload: {
        refId: content.refId,
        fields: {
          enableOptionalNode: !isOptionalContentEnabled,
        },
      },
    });
  };

  // Used as a temporary cheat for view content overflow menu option (when locked)
  const handleOpenTouchpointEditor = useCallback(() => {
    flowBuilderContext.sidePanels.push.setStepIndex(stepIndex);
    flowBuilderContext.sidePanels.push.setPushContext({
      isOpen: true,
      iconOverride: <IconContentFlow />,
      titleOverride: 'Content',
    });
  }, [flowBuilderContext.sidePanels.push, stepIndex]);

  const baseNodeProps = useMemo<Omit<BaseNodeProps, 'children'>>(() => {
    const menuItems: FlowNodeMenuItem[] = [];

    if (isJourneyLocked) {
      menuItems.push({
        icon: 'eyeOpenOutline',
        label: 'View Content',
        onClick: handleOpenTouchpointEditor,
      });
    } else if (!content.id) {
      menuItems.push(
        {
          icon: 'editFullOutline',
          label: 'Create new content',
          onClick: () => handleCreateAndSwapContent({ startingStep: CONTENT_MANAGER_STEPS.TEMPLATE_SELECTOR }),
          iconRight: getTooltipIcon(errorStatus),
          iconRightTooltip: errorMessage,
        },
        {
          icon: 'itemsList',
          label: 'Use existing content',
          onClick: () => handleCreateAndSwapContent({ startingStep: CONTENT_MANAGER_STEPS.CONTENT_SELECTOR }),
        },
      );
    } else {
      menuItems.push(
        {
          icon: 'editOutline',
          label: 'Edit Content',
          onClick: handleEditContent,
          iconRight: getTooltipIcon(errorStatus),
          iconRightTooltip: errorMessage,
        },
        {
          icon: 'swap',
          label: 'Swap Content',
          onClick: handleCreateAndSwapContent,
        },
        {
          icon: 'textBoxWithCorners',
          label: 'Rename',
          onClick: handleRenameContent,
        },
        {
          icon: 'crossCircleOutline',
          label: 'Remove Content',
          onClick: handleRemoveContent,
        },
      );
    }

    return {
      className: MAIN_CLASS,
      Icon: IconContent,
      IconTag: isMultiPageContent ? IconContentMultiPage : undefined,
      menuItems,
      iconUrl: contentIconUrl,
    };
  }, [
    isJourneyLocked,
    content.id,
    isMultiPageContent,
    contentIconUrl,
    handleOpenTouchpointEditor,
    errorStatus,
    errorMessage,
    handleCreateAndSwapContent,
    handleEditContent,
    handleRenameContent,
    handleRemoveContent,
  ]);

  const renderContentNode = () => (
    <div className={`${CLASSES.LABEL} ${isOptional && !isOptionalContentEnabled ? CLASSES.LABEL_DISABLED : ''}`}>
      <FlightTooltip description={`Content Name: ${contentDisplayName}`} className={CLASSES.NAME_TOOLTIP}>
        <div className={CLASSES.LABEL_TEXT}>{contentDisplayName}</div>
      </FlightTooltip>
      {contentNodeLabel && <div className={CLASSES.LABEL_NAME}>{`Type: ${contentNodeLabel}`}</div>}
    </div>
  );

  const shouldShowOptionalNodeWrapper = () => {
    if (isJourneyLocked) {
      return false;
    }

    let showWrapper = false;

    if (isEmpty(dependencyGraph?.[content.refId]?.isRefBy) && isOptional) {
      showWrapper = true;
    }

    return showWrapper;
  };

  return shouldShowOptionalNodeWrapper() ? (
    <OptionalNodeWrapper isEnabled={isOptionalContentEnabled} onToggle={handleToggleOptionalContent}>
      <BaseNode {...baseNodeProps} isOptional isDisabled={!isOptionalContentEnabled} status={errorStatus}>
        {renderContentNode()}
      </BaseNode>
    </OptionalNodeWrapper>
  ) : (
    <BaseNode {...baseNodeProps} status={errorStatus}>
      {renderContentNode()}
    </BaseNode>
  );
}

export default ContentNode;
