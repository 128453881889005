import React, { useEffect, useState } from 'react';
import './ChartTitleInput.scss';
import { FlightTextInput } from '@flybits/design-system';

const MAIN_CLASS = 'chart-title-selector';
interface ChartTitleInputProps {
  title?: string;
  errorMessage?: string;
  onChange: (title: string) => void;
}
export default function ChartTitleInput({ title, errorMessage, onChange }: ChartTitleInputProps) {
  const [chartTitle, setChartTitle] = useState(title || '');

  useEffect(() => {
    onChange(chartTitle.trim());
  }, [onChange, chartTitle, title]);
  return (
    <section className={MAIN_CLASS}>
      <FlightTextInput
        value={chartTitle}
        width={'100%'}
        placeholderText={'Untitled'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const name = e.target.value || '';
          setChartTitle(name);
        }}
        hasError={!!errorMessage}
        errorMessage={errorMessage}
        trailingIconTooltipDescription={errorMessage}
        label={''}
        ariaLabel={'Title your chart'}
      />
    </section>
  );
}
