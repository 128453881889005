import React, { useEffect, useState } from 'react';
import './TimeFrameSelector.scss';
import { epochToDateTimeString } from 'helpers/common.helper';
import { DATE_FORMAT_OPTIONS } from 'pages/AnalyticsV2/AnalyticsDashboard/constants';
import { FlightDateRangePicker, FlightSelect } from '@flybits/design-system';
import { AdvancedDateRange } from 'pages/AnalyticsV2/AnalyticsDashboard/types';
import { TIMEFRAME_INTERVAL_OPTIONS, timeFrameIntervalOption } from 'pages/Analytics/CustomModuleAnalytics/constants';

const MAIN_CLASS = 'timeframe-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  BLOCK: `${MAIN_CLASS}__block`,
  PREVIEW: `${MAIN_CLASS}__preview`,
  DATE_RANGE: `${MAIN_CLASS}__date-range`,
  DATE_RANGE_DD: `${MAIN_CLASS}__date-range__dropdown`,
};
interface TimeFrameSelectorProps {
  onChange: (data: { start: Date; end: Date; period: string }) => void;
}
export default function TimeFrameSelector({ onChange }: TimeFrameSelectorProps) {
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const dropdownOptions = timeFrameIntervalOption.map((interval) => {
    const { label, value } = interval;
    return { key: value, name: label };
  });
  const [selectTimeInterval, setSelectTimeInterval] = useState(dropdownOptions[1]);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59, 999);
      }
    }
  };
  useEffect(() => {
    if (selectTimeInterval && dateRange) {
      onChange({
        start: dateRange[0] ?? new Date(),
        end: dateRange[1] ?? new Date(),
        period: selectTimeInterval.key,
      });
    }
  }, [onChange, dateRange, selectTimeInterval]);
  return (
    <section className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="select-period">
        Select a period of time
      </label>
      <div className={CLASSES.BLOCK}>
        <FlightDateRangePicker
          className={`${CLASSES.DATE_RANGE_DD} half`}
          value={dateRange}
          onChange={handleDateRangeChange}
          maxDate={new Date()}
          includeAllTime={false}
          includeToday
          isIncludeCustom
          replaceCustomRangeLabel={false}
        />
        <div className={CLASSES.PREVIEW}>
          {dateRange[2] === 0
            ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
            : `${epochToDateTimeString(dateRange[0] || 0, 'en-US', DATE_FORMAT_OPTIONS)} - ${epochToDateTimeString(
                dateRange[1] || 0,
                'en-US',
                DATE_FORMAT_OPTIONS,
              )}`}
        </div>
      </div>
      <label className={CLASSES.LABEL} htmlFor="select-interval">
        Select an interval
      </label>
      <FlightSelect
        label=""
        className={`${CLASSES.DATE_RANGE_DD}`}
        options={dropdownOptions}
        selected={selectTimeInterval}
        width={'100%'}
        handleOptionClick={(option: { key: TIMEFRAME_INTERVAL_OPTIONS; name: string }) => {
          setSelectTimeInterval({
            key: option.key,
            name: option.name,
          });
        }}
      />
    </section>
  );
}
