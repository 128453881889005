import React, { useMemo, useState } from 'react';

import { FlightTable, FlightTextInput, getIcon, FlightDateRangePicker } from '@flybits/design-system';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ENGAGEMENT_DATA_TYPES, PushEngagementData, PushNotificationResponse } from 'components/Analytics/types';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import { CLASSES, DATE_FORMAT_OPTIONS } from '../constants';
import { AdvancedDateRange, DateRange } from '../types';
import './EngagementTables.scss';
import useDebounce from 'hooks/useDebounce';
import { JourneyRulePayload } from 'interface/rule/rule.interface';
import { PushUniversalActionTypes } from 'components/ExperienceCanvas/constants';
import FilterByLabel from 'components/Analytics/FilterByLabel/FilterByLabel';
import { LABEL_RULE } from 'components/Analytics/types';
import { epochToDateTimeString, intlNumberFormat } from 'helpers/common.helper';
import DownloadEngagementData from 'pages/AnalyticsV2/AnalyticsDashboard/Engagement/DownloadEngagementData';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as IconDownloadData } from 'assets/icons/icon-download-data.svg';

const tableHeaders = [
  {
    name: <>key</>,
    key: 'key',
    isVisible: false,
    isSortable: false,
  },
  {
    name: <span>Name</span>,
    key: 'name',
    isVisible: true,
    isSortable: false,
  },
  {
    name: <span>Associated context rule</span>,
    key: 'associated_context_rule',
    isVisible: true,
    isSortable: false,
  },
  {
    name: <span>Status</span>,
    key: 'status',
    isVisible: true,
    isSortable: false,
  },
  {
    name: (
      <span>
        Total /
        <br />
        Unique sent
      </span>
    ),
    key: 'unique_sent',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of times this push notification was sent, and the number of unique users it was sent to, respectively.',
  },
  {
    name: (
      <span>
        Total /
        <br />
        Unique delivered
      </span>
    ),
    key: 'total_unique_delivered',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of times this push notification was successfully delivered, and the number of unique users it was delivered to, respectively.',
  },
  {
    name: (
      <span>
        Total /
        <br />
        Unique bounces
      </span>
    ),
    key: 'unique_failed',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of push notifications that failed to get delivered, and the number of unique users it was failed to get delivered, respectively',
  },
  {
    name: (
      <span>
        Total /
        <br />
        Unique opens
      </span>
    ),
    key: 'unique_open',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of times this push notification was opened, and the number of unique users that opened it, respectively.',
  },
  {
    name: 'Unique open rate',
    key: 'unique_open_rate',
    isVisible: true,
    isSortable: true,
    tooltip: 'Unique opens divided by unique delivered.',
  },
];

const fetchPushNotifications = async (
  analyticsExperiencesApi: AnalyticsExperiencesAPI,
  page: number,
  perPage: number,
  dateRange: DateRange<Date>,
  search: string,
  labels: string,
  sortBy: string,
  sortOrder: string,
): Promise<PushEngagementData> => {
  const res = await analyticsExperiencesApi.getPushNotificationsEngagement({
    lastFrom: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
    lastTo: Math.trunc((dateRange[1]?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
    offset: (page - 1) * perPage,
    limit: perPage,
    search,
    labelsFormula: labels ? `(${labels})` : undefined,
    sortBy,
    sortOrder,
  });
  // we should catch the error, check status using isAxiosError and e.status,
  // then determining what to do from there, but for this first iteration it's fine
  const pushList: PushNotificationResponse[] = [];
  for (let i = 0; i < (res?.data?.Pushes?.length || 0); i++) {
    try {
      const pushRes = await analyticsExperiencesApi.getPushNotification(res.data.Pushes[i].pushId);
      pushList.push(pushRes);
    } catch (e) {}
  }

  const contextRules: JourneyRulePayload[] = [];
  for (let i = 0; i < (pushList.length || 0); i++) {
    const push = pushList[i];
    if (push) {
      for (let j = 0; j < push.rules.length; j++) {
        try {
          const ctxRuleRes = await analyticsExperiencesApi.getContextRule(push.rules[j].ruleID);
          contextRules.push(ctxRuleRes);
        } catch (e) {}
      }
    }
  }
  return {
    overview: res.data,
    pagination: res.pagination,
    pushes: pushList.length > 0 ? await Promise.all(pushList) : [],
    ctxRules: contextRules.length > 0 ? await Promise.all(contextRules) : [],
  };
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.PUBLISH,
  icon: <IconDownloadData />,
  title: 'Download push table',
  description: 'Note that the table will be downloaded based on your current filters',
  primaryAction: {
    value: 'Download',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};

const PushEngagementTable = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const [perPage, setPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const [queryDateRange, setQueryDateRange] = useState<AdvancedDateRange>([startOfToday, endOfToday]);
  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState<string[]>([]);
  const [labelRule, setLabelRule] = useState<LABEL_RULE>();
  const labelString = labelRule === LABEL_RULE.CONTAINS_ALL_OF ? labels.join(',') : labels.join(';');
  const [sortByColumn, setSortByColumn] = useState('unique_sent');
  const [sortOrder, setSortOrder] = useState('desc');
  const debouncedSearch = useDebounce(search, 300);
  const [pushDataForCSV, setPushDataForCSV] = useState<Record<string, string>[]>([]);
  const [isAllPushDataLoading, setAllPushDataLoading] = useState(false);
  const [DownloadConfirmModal, showDownloadConfirmModal] = useConfirmModal(confirmationDialogProps);

  const { data, status, isFetching, isLoading } = useQuery({
    queryKey: [
      'push-notifications-engagement',
      currentPage,
      perPage,
      queryDateRange,
      debouncedSearch,
      labelString,
      sortByColumn,
      sortOrder,
    ],
    queryFn: () =>
      fetchPushNotifications(
        analyticsExperiencesApi,
        currentPage,
        perPage,
        [queryDateRange[0], queryDateRange[1]],
        debouncedSearch,
        labelString,
        sortByColumn,
        sortOrder,
      ),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setCurrentPage(1);
    setSearch(value);
  };

  const handleLabelSelect = (selectedLabels: string[], labelRule: LABEL_RULE) => {
    setLabels(selectedLabels);
    setLabelRule(labelRule);
  };

  const handleHeaderSort = (e: { key: string }) => {
    if (e.key !== sortByColumn) {
      setSortByColumn(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59, 999);
      }

      setQueryDateRange([newDate[0], endOfDay]);
    }
  };

  const generateCSVRecords = (data: PushEngagementData): Record<string, string>[] => {
    return data.overview.Pushes?.map((pushOverview) => {
      const push = data.pushes.find((p) => pushOverview.pushId === p.pushRequestID) || null;
      const ctxRules = data.ctxRules.filter((ctx) => push?.rules.some((rule) => rule.ruleID === ctx.id));
      const subname = [];
      subname.push(push ? (push.pushType === 1 ? 'Manual' : 'Automatic') : '----');
      const payloadData = push?.body[0].payload.data;
      if (payloadData?.contentId?.entity === 'content') subname.push('In-App Content');
      else if (payloadData?.contentId?.url) subname.push('Web Link');
      else if (payloadData?.actionScheme) {
        if (payloadData.actionScheme['en']) {
          const actionLink = PushUniversalActionTypes.find((actionType) =>
            payloadData?.actionScheme?.['en'].includes(actionType.prefix),
          );
          subname.push(`Action Link (${actionLink?.name})` || 'Action Link');
        } else subname.push('Action Link');
      } else if (push) subname.push('Basic');

      return {
        'Push ID': pushOverview.pushId,
        'Push Name': push?.name || pushOverview.pushId,
        'Push Sub Name': subname.join(', '),
        'Associated Context Rules':
          ctxRules
            .map((rule) => rule.name)
            .reduce(
              (prevRuleName, currentRuleName) => `${prevRuleName}${prevRuleName ? ', ' : ''} ${currentRuleName}`,
              '',
            ) || '',
        Status: push?.isActive ? 'Active' : 'Inactive',
        'Total Sent': (pushOverview?.totalSent || 0).toLocaleString(),
        'Unique Sent': (pushOverview?.uniqueSent || 0).toLocaleString(),
        'Total Delivered': (pushOverview?.totalDelivery || 0).toLocaleString(),
        'Unique Delivered': (pushOverview?.uniqueDelivery || 0).toLocaleString(),
        'Total Failed': (pushOverview?.totalFailed || 0).toLocaleString(),
        'Unique Failed': (pushOverview?.uniqueFailed || 0).toLocaleString(),
        'Total Open': (pushOverview?.totalOpen || 0).toLocaleString(),
        'Unique Open': (pushOverview?.uniqueOpen || 0).toLocaleString(),
        'Unique Open Rate': `${((pushOverview?.uniqueOpenRate || 0) * 100).toFixed(2)}%`,
      };
    });
  };

  const handleClickDownload = async () => {
    try {
      if (await showDownloadConfirmModal()) {
        setAllPushDataLoading(true);
        const allData = await queryClient.fetchQuery({
          queryKey: [
            'all-push-notifications-engagement',
            queryDateRange,
            debouncedSearch,
            labelString,
            sortByColumn,
            sortOrder,
          ],
          queryFn: () =>
            fetchPushNotifications(
              analyticsExperiencesApi,
              1,
              100000, // Sending higher limit to BE to fetch all records
              [queryDateRange[0], queryDateRange[1]],
              debouncedSearch,
              labelString,
              sortByColumn,
              sortOrder,
            ),
        });

        if (!allData.pushes?.length) {
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              title: 'Info',
              content: 'No push engagement data found',
              type: 'info',
            },
          });
        } else {
          setPushDataForCSV(generateCSVRecords(allData));
        }
      }
    } catch {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Something went wrong and could not download push engagement data.',
          type: 'error',
        },
      });
    } finally {
      setAllPushDataLoading(false);
    }
  };

  return (
    <div className={CLASSES.CARD}>
      <div className={CLASSES.CARD_HEADER}>
        <h2>Push notifications</h2>
        <div className={CLASSES.DATE_RANGE}>
          {dateRange[2] === 0
            ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
            : `${epochToDateTimeString(dateRange[0] || 0, 'en-US', DATE_FORMAT_OPTIONS)} - ${epochToDateTimeString(
                dateRange[1] || 0,
                'en-US',
                DATE_FORMAT_OPTIONS,
              )}`}
        </div>
        <div>
          <FlightDateRangePicker
            className={CLASSES.DATE_RANGE_DD}
            value={dateRange}
            onChange={handleDateRangeChange}
            maxDate={new Date()}
            includeAllTime={false}
            includeToday
            isIncludeCustom
            replaceCustomRangeLabel={false}
          />
        </div>
        <FilterByLabel onLabelSelect={handleLabelSelect} />
        <div className={CLASSES.DOWNLOAD}>
          <DownloadEngagementData
            data={pushDataForCSV}
            type={ENGAGEMENT_DATA_TYPES.PUSH}
            isDownloading={isAllPushDataLoading}
            onClickDownload={handleClickDownload}
          />
        </div>
      </div>
      {isLoading && (
        <div className={CLASSES.SPINNER}>
          <LoadingIcon />
        </div>
      )}
      {status === 'success' && (
        <>
          <div className={CLASSES.OVERVIEW}>
            <div className={CLASSES.OVERVIEW_ITEM}>
              <p className={CLASSES.OVERVIEW_ITEM_TITLE}>Notifications sent</p>
              <p className={CLASSES.OVERVIEW_ITEM_DATA}>{data?.overview?.NotificationSent || 0}</p>
            </div>
            <div className={CLASSES.OVERVIEW_ITEM}>
              <p className={CLASSES.OVERVIEW_ITEM_TITLE}>Notifications delivered</p>
              <p className={CLASSES.OVERVIEW_ITEM_DATA}>{data?.overview?.NotificationSent || 0}</p>
            </div>
            <div className={CLASSES.OVERVIEW_ITEM}>
              <p className={CLASSES.OVERVIEW_ITEM_TITLE}>Notifications opened</p>
              <p className={CLASSES.OVERVIEW_ITEM_DATA}>{data?.overview?.NotificationOpened || 0}</p>
            </div>
            <div className={CLASSES.OVERVIEW_ITEM}>
              <p className={CLASSES.OVERVIEW_ITEM_TITLE}>Notifications open rate</p>
              <p className={CLASSES.OVERVIEW_ITEM_DATA}>
                {`${
                  !!data?.overview?.NotificationOpened && !!data?.overview?.NotificationSent
                    ? ((data?.overview?.NotificationOpened / data?.overview?.NotificationSent) * 100).toFixed(2)
                    : 0
                }%`}
              </p>
            </div>
          </div>
          <div className={CLASSES.SEARCH_WRAPPER}>
            <FlightTextInput
              onChange={handleSearchChange}
              value={search}
              placeholderText="Search"
              iconInput="search"
              width="280px"
              hasClearIcon
            />
          </div>
          <div className={CLASSES.TABLE_WRAPPER}>
            {labels.length > 0 && (
              <div className={CLASSES.LABEL_LIST}>
                {'Labels: '}
                {labelRule === LABEL_RULE.CONTAINS_ALL_OF ? 'contains all of ' : 'contains any of '}
                {labels.map((label) => (
                  <span className={CLASSES.LABEL} key={label}>
                    {label}
                  </span>
                ))}
              </div>
            )}
            <FlightTable
              tableHeaders={tableHeaders}
              tableData={data.overview.Pushes?.map((pushOverview) => {
                const push = data.pushes.find((p) => pushOverview.pushId === p.pushRequestID) || null;
                const ctxRules = data.ctxRules.filter((ctx) => push?.rules.some((rule) => rule.ruleID === ctx.id));
                const subname = [];
                subname.push(push ? (push.pushType === 1 ? 'Manual' : 'Automatic') : '----');
                const payloadData = push?.body[0].payload.data;
                if (payloadData?.contentId?.entity === 'content') subname.push('In-App Content');
                else if (payloadData?.contentId?.url) subname.push('Web Link');
                else if (payloadData?.actionScheme) {
                  if (payloadData.actionScheme['en']) {
                    const actionLink = PushUniversalActionTypes.find((actionType) =>
                      payloadData?.actionScheme?.['en'].includes(actionType.prefix),
                    );
                    subname.push(`Action Link (${actionLink?.name})` || 'Action Link');
                  } else subname.push('Action Link');
                } else if (push) subname.push('Basic');
                return {
                  key: pushOverview.pushId,
                  name: (
                    <>
                      <p>{push?.name || pushOverview.pushId}</p>
                      <p className={CLASSES.SUBNAME}>{subname.join(', ')}</p>
                    </>
                  ),
                  associated_context_rule: ctxRules.map((rule) => <p key={rule.id}>{rule.name}</p>),
                  status: push?.isActive ? (
                    <span className={`${CLASSES.PILL} ${CLASSES.ACTIVE_PILL}`}>Active</span>
                  ) : (
                    <span className={`${CLASSES.PILL} ${CLASSES.INACTIVE_PILL}`}>Inactive</span>
                  ),
                  unique_sent: (
                    <div className={CLASSES.TABLE_COMPLEX_NUMBER}>
                      <span title={intlNumberFormat(pushOverview?.totalSent || 0)}>
                        {intlNumberFormat(pushOverview?.totalSent || 0)}
                      </span>
                      /
                      <span title={intlNumberFormat(pushOverview?.uniqueSent || 0)}>
                        {intlNumberFormat(pushOverview?.uniqueSent || 0)}
                      </span>
                    </div>
                  ),
                  total_unique_delivered: (
                    <div className={CLASSES.TABLE_COMPLEX_NUMBER}>
                      <span title={intlNumberFormat(pushOverview?.totalDelivery || 0)}>
                        {intlNumberFormat(pushOverview?.totalDelivery || 0)}
                      </span>
                      /
                      <span title={intlNumberFormat(pushOverview?.uniqueDelivery || 0)}>
                        {intlNumberFormat(pushOverview?.uniqueDelivery || 0)}
                      </span>
                    </div>
                  ),
                  unique_failed: (
                    <div className={CLASSES.TABLE_COMPLEX_NUMBER}>
                      <span title={intlNumberFormat(pushOverview?.uniqueFailed || 0)}>
                        {intlNumberFormat(pushOverview?.uniqueFailed || 0)}
                      </span>
                      /
                      <span title={intlNumberFormat(pushOverview?.uniqueSent || 0)}>
                        {intlNumberFormat(pushOverview?.uniqueSent || 0)}
                      </span>
                    </div>
                  ),
                  unique_open: (
                    <div className={CLASSES.TABLE_COMPLEX_NUMBER}>
                      <span title={intlNumberFormat(pushOverview?.totalOpen || 0)}>
                        {intlNumberFormat(pushOverview?.totalOpen || 0)}
                      </span>
                      /
                      <span title={intlNumberFormat(pushOverview?.uniqueOpen || 0)}>
                        {intlNumberFormat(pushOverview?.uniqueOpen || 0)}
                      </span>
                    </div>
                  ),
                  unique_open_rate: `${((pushOverview?.uniqueOpenRate || 0) * 100).toFixed(2)}%`,
                };
              })}
              sortByKey={sortByColumn}
              sortOrder={sortOrder}
              handleHeaderSort={handleHeaderSort}
              hasPaginationBeforeTable={false}
              hasPaginationAfterTable={!!data?.pushes?.length}
              paginationProps={{
                totalPageNumber: Math.ceil(data.pagination.totalRecords / perPage) || 1,
                currentPageNumber: currentPage,
                rowsPerPageOptions: [5, 10, 15, 20],
                currentRowsPerPage: perPage,
                handlePageChange: (page: number) => setCurrentPage(page),
                handleRowsPerPageChange: (num: number) => setPerPage(num),
              }}
              isLoading={isFetching}
            />
            {!!!data?.overview?.Pushes?.length && !isFetching && (
              <div className={CLASSES.EMPTY_DATA}>
                {getIcon('search', {})}
                No results found
              </div>
            )}
          </div>
        </>
      )}
      <DownloadConfirmModal />
    </div>
  );
};

export default PushEngagementTable;
