// push editor box
import React, { useState, useEffect, useCallback } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useJourneyStatus from 'hooks/useJourneyStatus';
import useSettings from 'hooks/useSetting';
import useFeatureFlag from 'hooks/useFeatureFlag';
import usePushTypes from 'hooks/usePushTypes';
import useForm from 'hooks/useLocalizedSimpleForm';
import { PushValidators, getPushErrorList } from 'validators/ExperienceCanvas/push.validator';
import { mapPushTypes } from 'helpers/push.helper';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { getStepRuleSyntheticId } from 'store/helper';
import { getPushPayloadType } from 'store/push/push.selector';
import { selectStepRule } from 'store/rule/rule.selector';
import { insertNewPush, updatePushSimple } from 'store/push/push.thunk';
import { PushPayloadMetadata } from 'components/ExperienceCanvas/types';
import { START_TIME, TTimelineBoxProps } from 'pages/ExperienceCanvas/types';

import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';
import ActionLinkForm from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/ActionLinkForm';
import WeblinkForm from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/WeblinkForm';
import AdvancedForm from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/PushEditorAdvanced';
import { FlightTextInput, FlightTextArea } from '@flybits/design-system';
import CompleteIcon from 'pages/ExperienceCanvas/assets/icons/CompleteIcon';
import PushNotificationIcon from 'pages/ExperienceCanvas/assets/icons/PushNotificationIcon';
import InAppContentIcon from 'pages/ExperienceCanvas/assets/icons/InAppContentIcon';
import './PushEditorBox.scss';

function BasicForm() {
  return (
    <div className={`message-box success`}>
      <CompleteIcon />
      <span> Push will take user to home-screen or custom configured </span>
    </div>
  );
}

function ContentForm() {
  return (
    <div className={`message-box info`}>
      <InAppContentIcon />
      <span> The push will lead the user to the primary in-app content </span>
    </div>
  );
}

const DEFAULT_CLASS = 'push-editor';

export default function PushEditorBox({
  stepIdx,
  timelineIndex,
  onBlurTimelineBox = () => null,
  changePreviewLanguage = () => null,
}: TTimelineBoxProps) {
  const dispatch = useDispatch();
  const { flags } = useFeatureFlag();
  const { isJourneyLocked } = useJourneyStatus();
  const startScheduler = useSelector((state) => {
    const synthId = getStepRuleSyntheticId(stepIdx);
    return state.te.rule.startSchedulerMap[synthId];
  });
  const push = useSelector((state) => {
    const refId = state.te.journey.steps[stepIdx]?.push;
    return state.te.push.byRefId[refId];
  });
  const pushPayloadTypeConstrained = push?.constraints?.pushPayloadTypeId?.isReadOnly;
  const canCreatePush = useSelector((state) => {
    return !!selectStepRule(state.te, stepIdx);
  });
  const hasError = useSelector(
    (state) =>
      state.te.journey.steps[stepIdx]?.isDirty &&
      !!push &&
      (!push.isOptional || push.enableOptionalNode) &&
      !!getPushErrorList(state, push.refId).length,
  );

  const { languages, isInitialized: isLangInit } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const { pushTypes, isInitialized: isPushTypesInit } = usePushTypes();

  // map support pushTypes and shift basic onto it
  const [pushPayloadTabs, setPushPayloadTabs] = useState<PushPayloadMetadata[]>([]);
  const pushPayloadType = useSelector((state) => {
    return push?.pushPayloadType ?? getPushPayloadType(state, push?.refId)?.type ?? 'basic';
  });

  const selectPayloadType = useCallback(
    (pushType: PushPayloadMetadata) => {
      dispatch(
        updatePushSimple(
          {
            refId: push?.refId,
            fields: {
              pushPayloadType: pushType.key,
              pushPayloadTypeId: pushType.id || '',
            },
          },
          stepIdx,
          pushType.key,
        ),
      );
    },
    [dispatch, stepIdx, push],
  );

  useEffect(() => {
    if (isPushTypesInit && !pushPayloadTabs.length && push) {
      const newPayloadTabs = mapPushTypes(pushTypes);
      setPushPayloadTabs(newPayloadTabs);
      const newPayloadType = newPayloadTabs.find((type) => type.key === pushPayloadType);
      if (newPayloadType) selectPayloadType(newPayloadType);
      // reset the preview text direction to default
      selectLanguageTab(defaultLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push, flags, pushTypes, isPushTypesInit, pushPayloadTabs, pushPayloadType, selectPayloadType]);

  // set language tab
  const [selectedLang, setLanguage] = useState(isLangInit ? defaultLang : '');
  const [selectedLangDirection, setLanguageDirection] = useState(
    languages.find(({ id }) => id === defaultLang)?.direction || 'auto',
  );
  useEffect(() => {
    if (!selectedLang && isLangInit) {
      setLanguage(defaultLang);
    }
  }, [isLangInit, selectedLang, languages, defaultLang]);

  // generate form
  const {
    formData: pushBody,
    updateField,
    toggleError,
  } = useForm({
    data: {
      title: { value: push?.title?.[selectedLang] ?? '' },
      alert: { value: push?.alert?.[selectedLang] ?? '' },
    },
    validators: PushValidators,
    lang: selectedLang,
    defaultLang: defaultLang,
  });

  const [showAdvanced, setShowAdvanced] = useState(false);

  const selectLanguageTab = (lang: string) => {
    setLanguage(lang);
    const language = languages.find(({ id }) => id === lang);
    setLanguageDirection(language?.direction || 'auto');
    if (language) changePreviewLanguage(language);
  };

  const handleBlur = (key: 'title' | 'alert') => {
    toggleError(key);
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: push?.refId,
        fields: { [key]: { [selectedLang]: pushBody[key].value } },
      },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateField(name, value);
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: push?.refId,
        fields: { [name]: { [selectedLang]: value } },
      },
    });
  };

  const handlePushExpanded = useCallback(
    (value: boolean) => {
      if (!push) dispatch(insertNewPush(stepIdx));
      else {
        dispatch({
          type: 'UPDATE_PUSH_SIMPLE',
          payload: {
            refId: push.refId,
            fields: { enableOptionalNode: value },
          },
        });
      }
    },
    [dispatch, push, stepIdx],
  );

  useEffect(() => {
    if (startScheduler?.start === START_TIME.NOW) {
      handlePushExpanded(true);
    }
  }, [startScheduler, handlePushExpanded]);

  const RenderLanguageTabs = () => {
    if (!isLangInit) return null;

    return languages.map((lang) => {
      const activeClass = lang.id === selectedLang ? 'active' : '';
      return (
        <button
          key={`pel-${lang.id}`}
          className={`${DEFAULT_CLASS}__language-tab ${activeClass}`}
          onClick={() => selectLanguageTab(lang.id)}
        >
          {lang.name}
        </button>
      );
    });
  };

  const RenderPushPayloadTabs = () => {
    return pushPayloadTabs.map((payloadTab) => {
      const activeClass = payloadTab.key === pushPayloadType ? 'active' : '';
      return (
        <button
          key={`pep-${payloadTab.key}`}
          className={`${DEFAULT_CLASS}__payload-type-tab ${activeClass}`}
          aria-label={'select push payload type'}
          disabled={pushPayloadTypeConstrained}
          onClick={() => {
            selectPayloadType(payloadTab);
          }}
        >
          <payloadTab.icon fill="" className={`${DEFAULT_CLASS}__payload-type-tab-icon`} />
          <span>{payloadTab.title}</span>
        </button>
      );
    });
  };

  const RenderPushPayloadForm = () => {
    let Form;

    switch (pushPayloadType) {
      case 'basic':
        Form = BasicForm;
        break;
      case 'content':
        Form = ContentForm;
        break;
      case 'weblink':
        Form = WeblinkForm;
        break;
      case 'actionlink':
        Form = ActionLinkForm;
        break;
    }

    return Form ? (
      <Form refId={push?.refId} activeLang={selectedLang} defaultLang={defaultLang} stepIdx={stepIdx} />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (push?.pushPayloadType) {
      toggleError('title');
      toggleError('alert');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isPushTypesInit && isLangInit && (
        <TouchpointEditorCard
          title="Push Notification"
          icon={PushNotificationIcon}
          timelineIndex={timelineIndex}
          info={!push?.isOptional ? 'Required for launching experience' : ''}
          showExpandToggle={!push || push.isOptional}
          isToggleExpandedDisabled={!canCreatePush || startScheduler?.start === START_TIME.NOW}
          toggleExpanded={handlePushExpanded}
          toggleTooltip={
            !canCreatePush
              ? 'Requires audience configuration'
              : startScheduler?.start === START_TIME.NOW
              ? 'Required if start schedule is "Now"'
              : ''
          }
          isExpanded={(push && !push?.isOptional) || !!push?.enableOptionalNode}
          isLocked={isJourneyLocked}
          hasError={hasError}
        >
          <div className={DEFAULT_CLASS}>
            <div className={`${DEFAULT_CLASS}__language`}>
              <span>Selected Language</span>
              {RenderLanguageTabs()}
            </div>
            <div className={`${DEFAULT_CLASS}__push-body`} dir={selectedLangDirection}>
              <FlightTextInput
                name="title"
                className={`${DEFAULT_CLASS}__push-body-ti`}
                width={'100%'}
                label={'Title'}
                hasError={pushBody.title.showError}
                errorMessage={pushBody.title.error}
                value={pushBody.title.value}
                onChange={handleChange}
                direction={selectedLangDirection}
                maxLength={170}
                onBlur={() => {
                  if (!push?.title?.[selectedLang] && !push?.alert?.[selectedLang]) onBlurTimelineBox(undefined);
                  handleBlur('title');
                }}
                onFocus={() => onBlurTimelineBox('push')}
              />
              <span className={`${DEFAULT_CLASS}__push-body-subtext`} dir={selectedLangDirection}>
                Suggested maximum length: 1 line
              </span>
              <FlightTextArea
                name="alert"
                className={`${DEFAULT_CLASS}__push-body-ta`}
                width={'100%'}
                label="Message"
                hasError={pushBody.alert.showError}
                errorMessage={pushBody.alert.error}
                value={pushBody.alert.value}
                onChange={handleChange}
                direction={selectedLangDirection}
                onBlur={() => {
                  if (!push?.title?.[selectedLang] && !push?.alert?.[selectedLang]) onBlurTimelineBox(undefined);
                  handleBlur('alert');
                }}
                maxLength={170}
                onFocus={() => onBlurTimelineBox('push')}
              />
              <span className={`${DEFAULT_CLASS}__push-body-subtext`} dir={selectedLangDirection}>
                Suggested maximum length: 3 lines
              </span>
            </div>
            <div className={`${DEFAULT_CLASS}__payload-type`}>{RenderPushPayloadTabs()}</div>
            <div className={`${DEFAULT_CLASS}__payload-form`}>{RenderPushPayloadForm()}</div>
            <AdvancedForm
              classPrefix={DEFAULT_CLASS}
              onToggle={() => {
                setShowAdvanced((prev) => !prev);
              }}
              stepIdx={stepIdx}
              isExpanded={isJourneyLocked || showAdvanced}
            />
          </div>
        </TouchpointEditorCard>
      )}
    </>
  );
}
