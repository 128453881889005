import './MerchantSlideout.scss';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import {
  Merchant,
  MerchantCategory,
  MerchantsRouteParams,
  STATUS_ENTITIES,
} from 'pages/MerchantPortal/merchant-portal.types';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { FlightButton, getIcon } from '@flybits/design-system';
import MerchantAndOfferStatus from '../MerchantAndOfferStatus/MerchantAndOfferStatus';
import { capitalizeFirstCharacter } from 'helpers/common.helper';
import {
  ConfirmationDialogProps,
  ConfirmationModalActionStyles,
  ConfirmationModalTypes,
} from 'components/Shared/shared.types';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import merchantService from 'services/api/merchants.api';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconDeleteMerchant } from 'assets/icons/icon-delete-merchant.svg';
import useConfirmModal from 'hooks/useConfirmModal';

const MERCHANT_SLIDEOUT = 'merchant-slideout';
const MERCHANT_SLIDEOUT_CLASSES = {
  CONTAINER: `${MERCHANT_SLIDEOUT}__container`,
  CONTENT: `${MERCHANT_SLIDEOUT}__container__content`,
  SECTIONS: `${MERCHANT_SLIDEOUT}__container__content__sections`,
  LOGO: `${MERCHANT_SLIDEOUT}__container__content__logo`,
};

const MERCHANT_FIELD = `${MERCHANT_SLIDEOUT}__field`;
const MERCHANT_FIELD_CLASSES = {
  NAME: `${MERCHANT_FIELD}__name`,
  VAL: `${MERCHANT_FIELD}__val`,
};

const getFieldValue = (fieldKey: string, merchant: Merchant, categories?: MerchantCategory[]) => {
  switch (fieldKey) {
    case 'address':
      return (
        <div
          className={MERCHANT_FIELD_CLASSES.VAL}
        >{`${merchant.addressLine}, ${merchant.addressCity}, ${merchant.addressState}, ${merchant.addressZipCode}`}</div>
      );
    case 'verificationStatus':
      return (
        <MerchantAndOfferStatus
          type={STATUS_ENTITIES.MERCHANT_VERIFICATION_STATUS}
          status={merchant.verificationStatus}
          showTooltip
        />
      );
    case 'category':
      return (
        <div className={MERCHANT_FIELD_CLASSES.VAL}>
          {categories?.find((c) => c.id === merchant.category)?.name ?? capitalizeFirstCharacter(merchant.category)}
        </div>
      );
    default:
      return <div className={MERCHANT_FIELD_CLASSES.VAL}>{merchant[fieldKey as keyof Merchant] as string}</div>;
  }
};

interface MerchantFieldProps extends React.ComponentProps<'div'> {
  fieldKey: string;
  fieldName: string;
  merchant: Merchant;
  categories?: MerchantCategory[];
}

const MerchantField: React.FC<MerchantFieldProps> = ({ fieldKey, fieldName, merchant, categories }) => (
  <div className={MERCHANT_FIELD}>
    <div className={MERCHANT_FIELD_CLASSES.NAME}>{fieldName}:</div>
    {getFieldValue(fieldKey, merchant, categories)}
  </div>
);

const MERCHANT_SECTION = `${MERCHANT_SLIDEOUT}__section`;
const MERCHANT_SECTION_CLASSES = {
  HEADER: `${MERCHANT_SECTION}__header`,
  FIELDS: `${MERCHANT_SECTION}__fields`,
  LOGO: `${MERCHANT_SECTION}__logo`,
};

interface MerchantSectionProps extends React.ComponentProps<'div'> {
  header: string;
  fields: { [key: string]: string };
  categories?: MerchantCategory[];
  merchant: Merchant;
}

const MerchantSection: React.FC<MerchantSectionProps> = ({ header, fields, merchant, categories }) => {
  return (
    <div className={MERCHANT_SECTION}>
      <div className={MERCHANT_SECTION_CLASSES.HEADER}>{header}</div>
      <div className={MERCHANT_SECTION_CLASSES.FIELDS}>
        {Object.keys(fields).map((field) => (
          <MerchantField
            key={field}
            fieldKey={field}
            fieldName={fields[field]}
            merchant={merchant}
            categories={categories}
          />
        ))}
      </div>
    </div>
  );
};

const MERCHANT_PROFILE_FIELDS = {
  name: 'Merchant name',
  externalId: 'Merchant ID',
  category: 'Category',
  phoneNumber: 'Phone number',
  address: 'Address',
};

const MERCHANT_CONTACT_FIELDS = {
  contactName: 'Contact person',
  contactPhoneNumber: 'Phone number',
  contactEmail: 'Email',
};

const MERCHANT_STATUS_FIELDS = {
  verificationStatus: 'Verification Status',
  notes: 'Note',
};

const DELETE_MERCHANT = `${MERCHANT_SLIDEOUT}__delete`;
const DELETE_MERCHANT_CLASSES = {
  INFO: `${DELETE_MERCHANT}__info`,
  TITLE: `${DELETE_MERCHANT}__info__title`,
  DESC: `${DELETE_MERCHANT}__info__desc`,
};

interface DeleteMerchantProps extends React.ComponentProps<'div'> {
  merchant: Merchant;
  disabled?: boolean;
  setShowSlideout: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteMerchant: React.FC<DeleteMerchantProps> = ({ merchant, disabled, setShowSlideout }) => {
  const [isDeleting, setDeleting] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const isDelayedSubmitting = useDelayedLoadingState(isDeleting);
  const numOffers = merchant.offers?.length || 0;

  const confirmationDialogProps: ConfirmationDialogProps = useMemo(
    () => ({
      theme: ConfirmationModalTypes.DELETE,
      icon: <IconDeleteMerchant />,
      title: `Remove ${merchant.name}`,
      description: (
        <>
          This action is <b>permanent</b> and <b>cannot be undone</b>. Deleting this merchant will permanently remove
          all associated data{numOffers ? `, including ${numOffers} offer${numOffers > 1 ? 's' : ''}.` : '.'}
        </>
      ),
      primaryAction: {
        value: 'Delete Merchant',
      },
      secondaryAction: {
        value: 'Cancel',
      },
      actionStyle: ConfirmationModalActionStyles.COLUMN,
      isLoading: isDeleting,
      confirmationInput: {
        placeholderText: 'Enter merchant name',
        matchText: merchant.name,
        label: `Type “${merchant.name}” to confirm`,
      },
    }),
    [isDeleting, merchant.name, numOffers],
  );

  const [RemoveMerchantConfirmModal, showRemoveMerchantConfirmModal] = useConfirmModal(confirmationDialogProps);

  const handleDeleteMerchant = useCallback(async () => {
    try {
      if (await showRemoveMerchantConfirmModal()) {
        setDeleting(true);
        await merchantService.deleteMerchant(merchant.id);
        await queryClient.invalidateQueries({ queryKey: ['merchants'] });
        setShowSlideout(false);
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Success',
            content: `The Merchant "${merchant.name}" was deleted.`,
            type: 'success',
          },
        });
        setDeleting(false);
      }
    } catch (e: any) {
      let errorMessage = isAxiosError(e) || e instanceof Error ? e.message : 'unknown';
      // handles better error message from backend
      if (e?.response?.data?.error?.exceptionMessage) {
        errorMessage = e.response.data.error.exceptionMessage;
      }
      if (e?.response?.status === 403) {
        errorMessage = "You don't have access to remove this Merchant. Please contact Solutions.";
      }
      setSubmitErrorMessage(errorMessage);
      setDeleting(false);
    }
  }, [setShowSlideout, dispatch, merchant.id, merchant.name, queryClient, showRemoveMerchantConfirmModal]);

  useLayoutEffect(() => {
    if (!isDelayedSubmitting && submitErrorMessage) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: `The Merchant "${merchant.name}" could not be deleted. ${capitalizeFirstCharacter(
            submitErrorMessage,
          )}`,
          type: 'error',
        },
      });
    }
  }, [dispatch, isDelayedSubmitting, merchant.name, submitErrorMessage]);

  return (
    <>
      <div className={DELETE_MERCHANT}>
        <div className={DELETE_MERCHANT_CLASSES.INFO}>
          <div className={DELETE_MERCHANT_CLASSES.TITLE}>Delete merchant</div>
          <div className={DELETE_MERCHANT_CLASSES.DESC}>
            {disabled
              ? 'If you wish to remove a merchant from the system, please contact the Flybits Solutions team for assistance.'
              : 'This action cannot be undone and you will lose access to the profile'}
          </div>
        </div>
        <FlightButton
          type="warn"
          theme="secondary"
          label="Delete merchant"
          ariaLabel="Delete merchant"
          disabled={disabled}
          onClick={handleDeleteMerchant}
        />
      </div>
      <RemoveMerchantConfirmModal />
    </>
  );
};

interface MerchantSlideoutProps extends React.ComponentProps<'div'> {
  categories?: MerchantCategory[];
  merchant: Merchant;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const MerchantSlideout: React.FC<MerchantSlideoutProps> = ({ categories, merchant, show, setShow }) => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();
  // TODO: raise this to level 90 after merchant portal is released for M1
  const isFBAdmin = useSelector((state) => state.auth.user?.level && state.auth.user.level >= 21);
  const merchantSlideoutProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show,
      headerInfo: {
        mainTitle: 'View Merchant Information',
        showCloseButton: true,
        headerAction: isFBAdmin
          ? {
              label: 'Edit merchant',
              handler: () => history.push(`/project/${projectId}/merchants/${merchant.id}/edit-merchant`),
            }
          : undefined,
      },
      showFooter: false,
      footerInfo: {
        secondaryActionHandler: () => setShow(false),
      },
      className: MERCHANT_SLIDEOUT,
    }),
    [show, history, isFBAdmin, projectId, merchant.id, setShow],
  );

  return (
    <MemoizedSlidingSidePanel {...merchantSlideoutProps}>
      <div className={MERCHANT_SLIDEOUT_CLASSES.CONTAINER}>
        <div className={MERCHANT_SLIDEOUT_CLASSES.CONTENT}>
          <div className={MERCHANT_SLIDEOUT_CLASSES.SECTIONS}>
            <MerchantSection
              header="Merchant Profile"
              fields={MERCHANT_PROFILE_FIELDS}
              merchant={merchant}
              categories={categories}
            />
            <MerchantSection header="Merchant Contact Person" fields={MERCHANT_CONTACT_FIELDS} merchant={merchant} />
            <MerchantSection
              header="Merchant Verification Status"
              fields={MERCHANT_STATUS_FIELDS}
              merchant={merchant}
            />
          </div>
          <div className={MERCHANT_SLIDEOUT_CLASSES.LOGO}>
            {merchant.logoUrl ? <img src={merchant.logoUrl} alt="merchant logo" /> : getIcon('merchant', {})}
          </div>
        </div>
        <DeleteMerchant merchant={merchant} disabled={!isFBAdmin} setShowSlideout={setShow} />
      </div>
    </MemoizedSlidingSidePanel>
  );
};

export default MerchantSlideout;
