import React from 'react';
import './Preview.scss';
const MAIN_CLASS = 'chart-preview';
const CLASSES = {
  TITLE: `${MAIN_CLASS}__title`,
  CONTENT: `${MAIN_CLASS}__content`,
  NO_PREVIEWW: `${MAIN_CLASS}__content__empty`,
};
export default function Preview() {
  return (
    <div className={MAIN_CLASS}>
      <h2>Preview</h2>
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.NO_PREVIEWW}>Make selections in the left panels to begin seeing previews</div>
      </div>
    </div>
  );
}
