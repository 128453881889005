import React, { useState } from 'react';
import './ExperienceSelector.scss';
import { FlightTextInput } from '@flybits/design-system';
const MAIN_CLASS = 'experience-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  SELECT: `${MAIN_CLASS}__select`,
};
type ExperienceItem = {
  key: string;
  name: string;
};
interface ExperienceSelectorProps {
  experiences?: ExperienceItem[];
  selectedExperiencesIds?: string[];
  onChange?: (s: string[]) => void;
  isMultiple?: boolean;
}
/**
 * ExperienceSelector component allows users to search and select experiences from a list.
 *
 * @param {ExperienceSelectorProps} props - The properties for the ExperienceSelector component.
 * @param {ExperienceItem[]} [props.experiences] - An optional array of experience items to display in the selector.
 * @param {string[]} [props.selectedExperiencesIds] - An optional array of selected experience IDs.
 * @param {(s: string[]) => void} [props.onChange] - An optional callback function that is called when the selected experiences change.
 * @param {boolean} [props.isMultiple] - An optional boolean indicating if multiple selections are allowed.
 *
 */
export default function ExperienceSelector({
  experiences,
  selectedExperiencesIds,
  isMultiple,
}: ExperienceSelectorProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(selectedExperiencesIds);
  return (
    <div className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="experience-list">
        Select an Experience <span>*</span>
      </label>
      <FlightTextInput
        labelId={'experience-search'}
        iconInput="search"
        width="100%"
        value={searchTerm}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(evt.target.value)}
        placeholderText="Search"
      />
      <select
        className={CLASSES.SELECT}
        id="experience-list"
        aria-labelledby="experience-list"
        multiple={isMultiple}
        size={experiences?.length ?? 5}
        onChange={(e) => {
          setSelected(Array.from(e.target.selectedOptions).map((option) => option.value));
        }}
      >
        {experiences
          ?.filter((experience) => experience.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
          .map(({ key, name }) => (
            <option key={key} value={name} selected={selected?.includes(name)}>
              {name}
            </option>
          ))}
      </select>
    </div>
  );
}
