import './TargetAudienceNode.scss';

import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { RuleStateItem } from 'store/rule/rule.type';
import { getIsAudienceEmpty } from 'store/rule/rule.selector';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import BaseNode, { BaseNodeProps, FlowNodeMenuItem } from 'components/FlowBuilder/FlowNodes/BaseNode/BaseNode';
import IconTargetAudience from 'pages/FlowBuilder/assets/icons/IconTargetAudience';
import { FlightTooltip } from '@flybits/design-system';
import { isEmpty } from 'lodash';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { RuleActionTypes } from 'store/rule/rule.type';
import useJourneyStatus from 'hooks/useJourneyStatus';
import OptionalNodeWrapper from '../OptionalNodeWrapper/OptionalNodeWrapper';
import { validateStepTrigger } from 'validators/ExperienceCanvas/journey.validator';
import { TOUCHPOINT_STATUSES } from 'components/ExperienceCanvas/types';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { getTooltipIcon } from 'pages/FlowBuilder/helpers/flow-builder.helpers';

const MAIN_CLASS = 'target-audience-node';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  LABEL_DISABLED: `${MAIN_CLASS}__label--disabled`,
  LABEL_TEXT: `${MAIN_CLASS}__label__text`,
  LABEL_NAME: `${MAIN_CLASS}__label__name`,
  NAME_TOOLTIP: `${MAIN_CLASS}__name-tooltip`,
};

type TargetAudienceNodeProps = {
  data: {
    rule: RuleStateItem | null;
    stepIndex: number;
  };
};

function TargetAudienceNode(props: TargetAudienceNodeProps) {
  const {
    data: { rule, stepIndex },
  } = props;

  const dispatch = useDispatch();
  const { isJourneyLocked, hasActivationDate } = useJourneyStatus();
  const isProductionRule = !!rule?.visibility && rule.visibility !== 'draft';
  const isAudienceLocked = isJourneyLocked || hasActivationDate || isProductionRule;
  const isAudienceEmpty = useSelector((state) => getIsAudienceEmpty(state.te, stepIndex, true));
  const flowBuilderContext = useFlowBuilderContext();

  const handleEditTargetAudience = useCallback(
    (isViewOnlyMode = false) => {
      flowBuilderContext.sidePanels.targetAudience.setStepIndex(stepIndex);
      flowBuilderContext.sidePanels.targetAudience.setAudienceContext({ isOpen: true, isViewOnlyMode });
    },
    [flowBuilderContext.sidePanels.targetAudience, stepIndex],
  );

  const { errorStatus, errorMessage } = useSelector((state) => {
    const { errorStatus, errors } = validateStepTrigger(state, stepIndex);
    // currently only revealing the first error on a node.
    const errorMessage = errors[0]?.err ?? '';

    if (errorStatus === '') {
      return { errorStatus: TOUCHPOINT_STATUSES.COMPLETE, errorMessage };
    } else if (errorStatus === ERROR_TYPES.INCOMPLETE) {
      return { errorStatus: TOUCHPOINT_STATUSES.INCOMPLETE, errorMessage };
    } else {
      return { errorStatus: TOUCHPOINT_STATUSES.ERROR, errorMessage };
    }
  });

  const handleEditSchedule = useCallback(() => {
    flowBuilderContext.sidePanels.schedule.setStepIndex(stepIndex);
    flowBuilderContext.sidePanels.schedule.setIsOpen(true);
  }, [flowBuilderContext.sidePanels.schedule, stepIndex]);

  const handleRenameRule = useCallback(() => {
    flowBuilderContext.modals.rename.setData({
      entity: rule,
      entityType: JourneyComponentTypes.RULE,
      stepIndex,
    });
  }, [flowBuilderContext.modals.rename, rule, stepIndex]);

  const isOptional = rule && rule.isOptional;
  const isOptionalRuleEnabled = rule?.enableOptionalNode;
  const [isToggleChecked, setToggleChecked] = useState(!!isOptionalRuleEnabled);

  const handleToggleOptionalRule = () => {
    if (isAudienceEmpty) setToggleChecked((prev) => !prev);
    else {
      if (isToggleChecked) {
        dispatch({ type: RuleActionTypes.UPDATE_RULE_TRIGGER, payload: { stepIdx: stepIndex, type: 'omit' } });
      } else {
        dispatch({
          type: RuleActionTypes.UPDATE_RULE_TRIGGER,
          payload: { stepIdx: stepIndex, omitRule: 'default', isOptionalRuleEnabled: !isOptionalRuleEnabled },
        });
      }
    }
  };

  useEffect(() => {
    setToggleChecked((prev) => (isOptionalRuleEnabled === undefined ? prev : isOptionalRuleEnabled));
  }, [isOptionalRuleEnabled]);

  const baseNodeProps = useMemo<Omit<BaseNodeProps, 'children'>>(() => {
    const menuItems: FlowNodeMenuItem[] = [];

    if (isJourneyLocked || hasActivationDate || isProductionRule) {
      menuItems.push({
        icon: 'eyeOpenOutline',
        label: 'View Target Audience',
        onClick: () => handleEditTargetAudience(true),
      });
    } else {
      menuItems.push(
        {
          icon: 'editOutline',
          label: 'Edit Target Audience',
          onClick: () => handleEditTargetAudience(false),
          iconRight: getTooltipIcon(errorStatus),
          iconRightTooltip: errorMessage,
        },
        {
          icon: 'textBox',
          label: 'Rename',
          onClick: handleRenameRule,
        },
      );
    }
    if (!isJourneyLocked) {
      menuItems.unshift({
        icon: 'editOutline',
        label: 'Edit Schedule',
        onClick: handleEditSchedule,
      });
    }

    return { className: MAIN_CLASS, menuItems, Icon: IconTargetAudience };
  }, [
    isJourneyLocked,
    hasActivationDate,
    isProductionRule,
    handleEditTargetAudience,
    errorStatus,
    errorMessage,
    handleRenameRule,
    handleEditSchedule,
  ]);

  const getRuleNodeLabel = () => {
    let label = 'Everyone';

    if (!rule) {
      return label;
    }

    if (!rule.isOptional || !isEmpty(rule.predicates)) {
      label = 'Target Audience';
    }

    return label;
  };

  const renderRuleNode = () => (
    <div className={`${CLASSES.LABEL} ${isOptional && !isToggleChecked ? CLASSES.LABEL_DISABLED : ''}`}>
      <FlightTooltip description={`Rule Name: ${rule?.name || 'Audience group'}`} className={CLASSES.NAME_TOOLTIP}>
        <div className={CLASSES.LABEL_TEXT}>{rule?.name || 'Audience group'}</div>
      </FlightTooltip>
      <div className={CLASSES.LABEL_NAME}>Type: {getRuleNodeLabel()}</div>
    </div>
  );

  const showOptionalNodeWrapper = !isAudienceLocked && isOptional;

  return showOptionalNodeWrapper ? (
    <OptionalNodeWrapper isEnabled={isToggleChecked} onToggle={handleToggleOptionalRule}>
      <BaseNode {...baseNodeProps} isOptional isDisabled={!isToggleChecked} status={errorStatus}>
        {renderRuleNode()}
      </BaseNode>
    </OptionalNodeWrapper>
  ) : (
    <BaseNode {...baseNodeProps} isDisabled={rule?.isOptional && !rule.enableOptionalNode} status={errorStatus}>
      {renderRuleNode()}
    </BaseNode>
  );
}

export default TargetAudienceNode;
