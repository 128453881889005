/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import {
  FlightTable,
  FlightLabel,
  FlightButton,
  FlightSearch,
  FlightSelectMulti,
  FlightSnackbar,
  FlightOverflowMenu,
} from '@flybits/design-system';
import './LocationManagement.scss';
import UploadLocation from '../UploadLocation/UploadLocation';
import axios from 'axios';
import Storage from 'services/storage';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { MapContainer } from '../MapContainer/MapContainer';
import { Label } from 'interface/experience/experience.interface';
import LabelsAPI from 'services/api/labels.api';
import LocationAPI from 'services/api/location.api';
import moment from 'moment';
import { isEqual, size, debounce } from 'lodash';
import GoogleMapWrapper from '../MapContainer/GoogleMapWrapper';
import {
  RuleBuilderSidePanel,
  RuleBuilderManageLocation,
  TRuleBuilderSidePanelProps,
  RB_SIDE_PANEL_THEMES,
  RULE_BUILDER_SAVE_LOCATION_STATUSES,
  RuleBuilderSaveLocationStatus,
  RuleBuilderLocationPayload,
} from '@flybits/webapp-react-rule-builder';
import LocationHeaderIcon from 'pages/ExperienceCanvas/assets/icons/LocationHeaderIcon';
import { Location } from 'interface/location/location.interface';
import { saveLocationThunk } from 'store/location/location.thunk';
import useLocations from 'hooks/useLocations';
import ActionsIcon from 'pages/ExperienceCanvas/assets/icons/ActionsIcon';
import { getTenantId } from 'helpers/templated-experience.helper';
import { calculateCenter } from 'helpers/location.helper';
import useConfirmModal from 'hooks/useConfirmModal';
import { ReactComponent as IconDeleteModal } from 'assets/icons/icon-delete-modal.svg';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';

type TableData = {
  key?: string;
  name?: JSX.Element;
  address?: JSX.Element;
  labels?: JSX.Element;
  createdAt?: JSX.Element;
  option?: JSX.Element;
};

const tableHeaders = [
  {
    name: '',
    key: 'id',
    isVisible: false,
    hideTooltip: true,
  },
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Address',
    key: 'address',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Labels',
    key: 'labels',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Date Created',
    key: 'createdAt',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Options',
    key: 'option',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
];

const initLocationPayload = {
  name: '',
  address: '',
  shape: [[]],
  labels: [],
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.DELETE,
  icon: <IconDeleteModal />,
  title: 'Are you sure you want to delete this location?',
  description: 'Deleting is permanent and cannot be undone.',
  primaryAction: { value: 'Delete location' },
  secondaryAction: { value: 'Cancel' },
  warningMessage:
    'If this location is in a target audience, users there will no longer see content or push notifications.',
};

// CLASSES
const CLASS_MAIN = 'location-management';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  EMPTY_STATE: `${CLASS_MAIN}__empty-state`,
};

export default function LocationManagement() {
  const labelsAPI = useMemo(() => new LabelsAPI(), []);
  const locationsAPI = useMemo(() => new LocationAPI(), []);
  const storage = useMemo(() => new Storage(), []);

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    sortby: 'createdAt',
    sortorder: 'desc',
    search: '',
    labelsFormula: '',
  });
  const [locations, setLocations] = useState<Location[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [labelsForLocationsTable, setLabelsForLocationsTable] = useState<Label[]>([]);
  const [showNewLocationSidePanel, setShowNewLocationSidePanel] = useState(false);
  const [contextLabels, setContextLabels] = useState<string[]>([]);
  const [locationCoordinates, setLocationCoordinates] = useState<any[]>([]);
  const [labelsForAddNewLocation, setLabelsForAddNewLocation] = useState<string[]>([]);
  const [isUniqueLocationNameError, setUniqueLocationNameError] = useState(false);
  const [showLocationSnackbar, setShowLocationSnackbar] = useState(false);
  const [locationSnackbarMessage, setLocationSnackbarMessage] = useState<string>('');
  const [locationSnackbarType, setLocationSnackbarType] = useState('error');
  const [locationPayload, setLocationPayload] = useState<RuleBuilderLocationPayload>(initLocationPayload);
  const [selectedLocation, setSelectedLocation] = useState<RuleBuilderLocationPayload>(initLocationPayload);
  const [isUploadLocationOpen, setIsUploadLocationOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const handleDismissUploadLocation = useCallback(() => {
    setIsUploadLocationOpen(false);
    getAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnHeaderSort = useCallback(
    (header: any) => {
      let key = pagination.sortby;
      let sortorder = 'desc';
      if (header?.key === pagination.sortby) {
        sortorder = pagination.sortorder === 'desc' ? 'asc' : 'desc';
      } else {
        key = header?.key;
      }
      setPagination((pagination) => ({
        ...pagination,
        sortorder,
        sortby: key,
        offset: 0,
      }));
    },
    [pagination],
  );

  const slidingSidePanelProps: TSlidingSidePanelProps = {
    show: isUploadLocationOpen,
    headerInfo: {
      mainTitle: '',
      goBackActionHandler: handleDismissUploadLocation,
      goBackIcon: <ActionsIcon fill="#ffffff" />,
      goBackTitle: 'Upload Location',
    },
    showFooter: false,
  };
  const getAllLabels = useCallback(() => {
    (async () => {
      try {
        const res = await labelsAPI.getLabels();
        const labels = res.data || [];
        const serializedLabels = labels?.map((label: string) => ({
          key: label,
          name: label,
          isSelected: false,
        }));

        setContextLabels(labels);
        setLabelsForLocationsTable(serializedLabels);
      } catch {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: 'Could not get the labels', type: 'error' },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAllLocations = useCallback((isLabelsCallRestricted?: boolean, updatedPagination?: any) => {
    (async () => {
      const res = await locationsAPI.getLocations(updatedPagination ? updatedPagination : { ...pagination });
      const locations = res.data || [];
      const _locationCoordinates: any[] = [];
      const totalRecords = res.pagination.totalRecords;
      const limit = res.pagination.limit;
      const labelsForAddNewLocation: string[] = [];
      const visitedLabels: { [key: string]: boolean } = {};

      locations.forEach((location: any) => {
        _locationCoordinates.push(calculateCenter(location.shape[0]));
        location.labels?.forEach((label: string) => {
          if (!visitedLabels[label]) {
            visitedLabels[label] = true;

            labelsForAddNewLocation.push(label);
          }
        });
      });
      setLocationCoordinates(_locationCoordinates);
      setLocations(locations);
      setLabelsForAddNewLocation(labelsForAddNewLocation);
      const maxPage = Math.ceil(totalRecords / limit);
      setMaxPage(maxPage ? maxPage : 1);
      if (!isLabelsCallRestricted) {
        getAllLabels();
      }
      setIsTableDataLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [DeleteLocationConfirmModal, showDeleteLocationConfirmModal] = useConfirmModal(confirmationDialogProps);

  useEffect(() => {
    setIsTableDataLoading(true);
    getAllLocations(true, pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);
  useEffect(() => {
    getAllLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { isLocationSaving } = useLocations();

  const saveLocationCallback = useCallback((saveStatusInfo?: RuleBuilderSaveLocationStatus) => {
    if (saveStatusInfo && saveStatusInfo.status === RULE_BUILDER_SAVE_LOCATION_STATUSES.ERROR) {
      setLocationSnackbarType(RULE_BUILDER_SAVE_LOCATION_STATUSES.ERROR);
      setLocationSnackbarMessage(saveStatusInfo.statusMessage || 'Location could not be saved!');
      setShowLocationSnackbar(true);
    } else {
      // ToDo - The side panel will be kept open if users want to edit the location right after creating it
      setLocationPayload(initLocationPayload);
      setShowNewLocationSidePanel(false);
    }
  }, []);

  const handleSaveLocation = useCallback(() => {
    const ruleSidePanelBody = document.querySelector('.rule-builder-side-panel__body') as HTMLDivElement;

    if (locationPayload) {
      if (!locationPayload.name) {
        setLocationSnackbarMessage('Enter a name');
        setShowLocationSnackbar(true);
        ruleSidePanelBody.scrollTop = ruleSidePanelBody.scrollHeight;
      } else if (isUniqueLocationNameError) {
        setLocationSnackbarMessage('Location name is not unique');
        setShowLocationSnackbar(true);
        ruleSidePanelBody.scrollTop = ruleSidePanelBody.scrollHeight;
      } else if (locationPayload.shape?.[0]?.length <= 0) {
        setLocationSnackbarMessage('Create a shape');
        setShowLocationSnackbar(true);
        ruleSidePanelBody.scrollTop = ruleSidePanelBody.scrollHeight;
      } else {
        if (isEditMode) {
          dispatch(saveLocationThunk(locationPayload, saveLocationCallback, selectedLocationId));
        } else {
          dispatch(saveLocationThunk(locationPayload, saveLocationCallback));
        }
        setPagination((pagination) => ({
          ...pagination,
          sortorder: 'desc',
          sortby: 'createdAt',
          offset: 0,
        }));
        setLocationSnackbarMessage('');
        setShowLocationSnackbar(false);
        setIsEditMode(false);
      }
    } else {
      setLocationSnackbarMessage('Enter name');
      setShowLocationSnackbar(true);
      ruleSidePanelBody.scrollTop = ruleSidePanelBody.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPayload, saveLocationCallback, isUniqueLocationNameError, dispatch]);

  const handleDeleteLocation = useCallback(
    async (selectDeletedID: string, selectedDeletedName: string) => {
      if (!selectDeletedID) {
        return;
      }
      if (await showDeleteLocationConfirmModal()) {
        const origin = process.env.REACT_APP_API_URL;
        const url = `${origin}/context/location/${selectDeletedID}`;
        const tenantId = getTenantId();
        const token = await storage.getItem(`${tenantId}+token`);
        await axios({
          method: 'delete',
          url: url,
          headers: { 'X-Authorization': token },
        })
          .then(function () {
            dispatch({
              type: 'SHOW_SNACKBAR',
              payload: {
                content: `The location ${selectedDeletedName || 'selected'} has been deleted successfully`,
                type: 'success',
              },
            });
            getAllLocations();
          })
          .catch(function (error) {
            const errorMessage = error?.response?.data?.error?.exceptionMessage || 'Something went wrong!';
            dispatch({
              type: 'SHOW_SNACKBAR',
              payload: { content: errorMessage, type: 'error' },
            });
          });
      }
    },
    [dispatch, getAllLocations, storage, showDeleteLocationConfirmModal],
  );

  const onEdit = async (location: any) => {
    try {
      const origin = process.env.REACT_APP_API_URL;
      const url = `${origin}/context/location/${location.id}`;
      const tenantId = getTenantId();
      const token = await storage.getItem(`${tenantId}+token`);
      const payload = await axios({
        method: 'get',
        url: url,
        headers: { 'X-Authorization': token },
      });
      setSelectedLocationId(payload?.data?.id);
      setIsEditMode(true);
      setSelectedLocation(() => ({
        name: payload?.data?.name,
        address: payload?.data?.address,
        shape: payload?.data?.shape,
        labels: payload?.data?.labels,
      }));
    } finally {
      setShowNewLocationSidePanel(true);
    }
  };
  const renderDropdown = (location: any) => {
    return [
      [
        {
          key: 'opt1',
          name: 'Edit',
          onClick: () => {
            onEdit(location);
          },
        },
        {
          key: 'opt2',
          name: 'Delete',
          onClick: () => {
            handleDeleteLocation(location.id, location.name);
          },
        },
      ],
    ];
  };
  useEffect(() => {
    document.title = 'Location Management | Experience Studio @ Flybits';
  }, []);

  useEffect(() => {
    const _tableData: TableData[] = [];
    locations?.forEach((location: any) => {
      _tableData.push({
        key: String(location.id),
        name: (
          <span className="location-management__content__table__content">
            <b>{location.name}</b>
          </span>
        ),
        address: <span className="location-management__content__table__content">{location.address}</span>,
        labels: (
          <span className="location-management__content__table__content__labels">
            {location?.labels?.map((label: any, index: any) => (
              <FlightLabel label={label} key={index} />
            ))}
          </span>
        ),
        createdAt: (
          <span className="location-management__content__table__content">
            {moment.unix(location.createdAt).utc().format('MMM DD, YYYY')}
          </span>
        ),
        option: (
          <FlightOverflowMenu
            className="location-management__content__table__select"
            optionGroups={renderDropdown(location)}
            direction={'bottom'}
            isOpenRight={false}
            disabled={false}
          />
        ),
      });
    });
    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const getCurrentPageNumber = () => {
    const totalPages = Math.ceil(size(locations) / pagination.limit);
    return totalPages + 1 - Math.ceil((size(locations) - pagination.offset) / pagination.limit);
  };

  const handleOnPageChange = (page: number) => {
    const nextOffset = page - 1;
    setPagination((pagination) => ({
      ...pagination,
      offset: pagination.limit * nextOffset,
    }));
  };

  const handleOnRowPerPageChange = (amount: number) => {
    setPagination((pagination) => ({
      ...pagination,
      limit: amount,
      offset: 0,
    }));
  };

  const handleUploadLocation = () => {
    setIsUploadLocationOpen(true);
  };

  const debouncedHandleSearchLocation = useMemo(
    () =>
      debounce((search: string) => {
        setPagination((pagination) => ({
          ...pagination,
          search,
        }));
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination],
  );

  function updateLocationsLabelsOnSearch(searchText: string) {
    setLabelsForLocationsTable((labels) =>
      labels.filter((label: Label) => label.name.toLowerCase().includes(searchText.toLowerCase())),
    );
  }

  function updateLocationsLabelsOnSelect(selectedLabel: Label) {
    const selectedLabels: Label[] = [];
    const _locationsLabels = labelsForLocationsTable.map((label: Label) => {
      if (label.key === selectedLabel.key) {
        label.isSelected = !label.isSelected;
        selectedLabel.isSelected = label.isSelected;
      }
      if (label.isSelected) {
        selectedLabels.push(label);
      }
      return label;
    });
    setLabelsForLocationsTable(_locationsLabels);
    setPagination((pagination) => ({
      ...pagination,
      labelsFormula:
        selectedLabels.length > 0 ? `(${selectedLabels.map((selectedLabel) => selectedLabel.name)?.join(',')})` : '',
    }));
  }

  const rbNewLocationSidePanelProps: TRuleBuilderSidePanelProps = {
    show: showNewLocationSidePanel,
    theme: RB_SIDE_PANEL_THEMES.EXTERNAL_APP,
    headerInfo: {
      title: isEditMode ? 'Edit Location' : 'New Location',
      icon: <LocationHeaderIcon />,
    },
    footerInfo: {
      primaryActionText: isEditMode ? 'Save Location' : 'Create Location',
      primaryActionHandler: handleSaveLocation,
      isPrimaryActionInProgress: isLocationSaving,
      secondaryActionText: 'Cancel',
      secondaryActionHandler: () => {
        setShowLocationSnackbar(false);
        setLocationSnackbarMessage('');
        setUniqueLocationNameError(false);
        setLocationPayload(initLocationPayload);
        setShowNewLocationSidePanel(false);
        setIsEditMode(false);
      },
    },
  };

  return (
    <div className="location-management">
      <div className="location-management__header">
        <h1 className="location-management__header__title">Location Management</h1>
        <div className="location-management__header__content">
          <div className="location-management__header__content__filters">
            <FlightSearch
              className="location-management__header__content__filters__select"
              onSearch={debouncedHandleSearchLocation}
              label="Search for a location"
            />
            <FlightSelectMulti
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              isSearchEnabled
              className="location-management__header__content__filters__label"
              width="200px"
              label="Select a label"
              searchPlaceholder="Search"
              options={labelsForLocationsTable}
              handleSearch={updateLocationsLabelsOnSearch}
              handleOptionSelect={updateLocationsLabelsOnSelect}
            />
            {/* FlightDropdown with a FlightMultiSelect in it */}
          </div>
          <div className="location-management__header__content__buttons">
            <FlightButton
              label="Upload Location"
              size="large"
              theme="secondary"
              className="location-management__header__create-button"
              onClick={handleUploadLocation}
            />
            <FlightButton
              label="New Location"
              size="large"
              iconLeft={'add'}
              className="location-management__header__create-button"
              onClick={() => setShowNewLocationSidePanel(true)}
            />
          </div>
        </div>
      </div>
      <div className="location-management__content">
        <GoogleMapWrapper
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ''}
          version={'weekly'}
          libraries={['geocoding', 'places', 'geometry']}
        >
          <MapContainer locationCoordinates={locationCoordinates} />
        </GoogleMapWrapper>
        <FlightTable
          isLoading={isTableDataLoading}
          className="location-management__content__table"
          tableHeaders={tableHeaders}
          tableData={tableData}
          emptyState={
            <div className={CLASSES.EMPTY_STATE}>
              <MagnifierIcon />
              <div>No results found</div>
            </div>
          }
          hasPaginationAfterTable
          handleHeaderSort={handleOnHeaderSort}
          hasPaginationBeforeTable={false}
          sortByKey={pagination.sortby}
          sortOrder={pagination.sortorder.toLowerCase()}
          paginationProps={{
            totalPageNumber: maxPage,
            currentPageNumber: getCurrentPageNumber(),
            rowsPerPageOptions: [10, 20, 30, 40, 50],
            currentRowsPerPage: Number(pagination.limit),
            handlePageChange: handleOnPageChange,
            handleRowsPerPageChange: handleOnRowPerPageChange,
          }}
        />
      </div>
      <RuleBuilderSidePanel {...rbNewLocationSidePanelProps}>
        <GoogleMapWrapper
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ''}
          version={'weekly'}
          libraries={['geocoding', 'places', 'geometry']}
        >
          <RuleBuilderManageLocation
            locationPayload={locationPayload}
            selectedLocation={selectedLocation}
            isEditMode={isEditMode}
            contextLabels={contextLabels}
            locationLabels={labelsForAddNewLocation}
            locations={locations}
            setLocationPayload={setLocationPayload}
            isUniqueLocationNameError={isUniqueLocationNameError}
            setUniqueLocationNameError={setUniqueLocationNameError}
            setShowLocationSnackbar={setShowLocationSnackbar}
            setLocationSnackbarMessage={setLocationSnackbarMessage}
          />
        </GoogleMapWrapper>
        <FlightSnackbar
          className="create-location-snack-bar"
          type={locationSnackbarType}
          isVisible={showLocationSnackbar}
          isFloating={false}
          action={null}
          content={locationSnackbarMessage}
          handleClose={() => setShowLocationSnackbar(false)}
        />
      </RuleBuilderSidePanel>
      <SlidingSidePanel {...slidingSidePanelProps}>
        <UploadLocation />
      </SlidingSidePanel>
      <DeleteLocationConfirmModal />
    </div>
  );
}
