import React, { useState } from 'react';
import './ChartConfigurationType.scss';
import { FlightTabs, getIcon } from '@flybits/design-system';
import { ReactComponent as RecommendedIcon } from 'assets/icons/recommended.svg';
import { CHART_CONFIGURATION_TYPES } from 'pages/AnalyticsV2/ExperiencesDashboard/CreateOrEditChart/constants';
const MAIN_CLASS = 'chart-config-selector';
const CLASSES = {
  TABS: `${MAIN_CLASS}__tabs`,
};
interface ChartConfigurationTypeProps {
  currrentSelection?: CHART_CONFIGURATION_TYPES;
  onSelect?: (s: CHART_CONFIGURATION_TYPES) => void;
}
export default function ChartConfigurationType({ currrentSelection, onSelect }: ChartConfigurationTypeProps) {
  const [selectedTab, setSelectedTab] = useState<string>(currrentSelection || CHART_CONFIGURATION_TYPES.RECOMMENDED);
  const selectTab = (selected: CHART_CONFIGURATION_TYPES) => {
    setSelectedTab(selected);
    onSelect && onSelect(selected);
  };
  return (
    <div className={MAIN_CLASS}>
      <FlightTabs
        labels={[
          {
            key: CHART_CONFIGURATION_TYPES.RECOMMENDED,
            name: (
              <>
                <RecommendedIcon /> Recommendations
              </>
            ),
          },
          { key: CHART_CONFIGURATION_TYPES.DO_IT_YOURSELF, name: <>{getIcon('build', {})} Do it yourself</> },
        ]}
        className={CLASSES.TABS}
        selectedTab={selectedTab}
        onSelect={(e: { key: CHART_CONFIGURATION_TYPES }) => {
          selectTab(e.key);
        }}
      />
    </div>
  );
}
