import React, { useContext, useMemo } from 'react';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import DynamicContentManager from 'pages/DynamicContentManager/DynamicContentManager';
import ContentManagerSlideoutIcon from 'pages/ZonesV2/Icons/ContentManagerSlideoutIcon';

/**
 * The slide out page for content manager from zone details.
 */
const ContentManagerSlideout: React.FC<React.ComponentProps<'div'>> = () => {
  const { showContentManagerSlideout, setShowContentManagerSlideout } = useContext(PrioritizationContext);

  const contentManagerSlideoutProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: showContentManagerSlideout.show,
      headerInfo: {
        mainTitle: '',
        goBackTitle: 'Content Manager',
        goBackSubTitle: 'Create and manage existing content',
        goBackIcon: <ContentManagerSlideoutIcon />,
        goBackActionHandler: () =>
          setShowContentManagerSlideout(() => {
            return { show: false };
          }),
      },
      showFooter: false,
      footerInfo: {
        primaryActionText: '',
        secondaryActionText: 'Cancel',
        secondaryActionHandler: () =>
          setShowContentManagerSlideout(() => {
            return { show: false };
          }),
      },
    }),
    [showContentManagerSlideout, setShowContentManagerSlideout],
  );

  return (
    <MemoizedSlidingSidePanel {...contentManagerSlideoutProps}>
      <DynamicContentManager
        contentId={showContentManagerSlideout.contentId}
        moduleId={showContentManagerSlideout.moduleId}
        openPinnedTab={showContentManagerSlideout.openPinnedTab}
      />
    </MemoizedSlidingSidePanel>
  );
};

export default ContentManagerSlideout;
